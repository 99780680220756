import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {memo, type PropsWithChildren, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import type {Locale, LocaleObject} from '../@types/Locale'
import {locales} from '../enums/locale'
import {useUserHelperGetCurrent} from '../hooks/entity/useUser'
import {useSetDayjs} from '../states/dayJs'
import {useLocationHash, useLocationPathname, useLocationSearch, useParam} from '../states/location'

const getPathnameWithoutLang = (pathname: string, lang: Locale | undefined): string => {
  return `${lang ? pathname.substring(lang.length + 1) : pathname}`
}

const getUrlForLang = (pathname: string, currentLang: Locale | undefined, lang?: Locale): string => {
  const url = new URL(window.location.href)
  if (lang) {
    url.pathname = `/${lang}${getPathnameWithoutLang(pathname, currentLang)}`
  } else {
    url.pathname = getPathnameWithoutLang(pathname, currentLang)
  }

  return url.href
}

interface I18nHtmlProps {
  locale: string | undefined
}

const I18nHtml = memo(({locale}: I18nHtmlProps) => {
  useEffect(() => {
    if (locale) {
      document.documentElement.setAttribute('lang', locale)
    } else {
      document.documentElement.removeAttribute('lang')
    }
  }, [locale])

  return null
})

interface I18nLinkProps {
  hreflang?: Locale | 'x-default'
  href: string
  rel: 'canonical' | 'alternate'
}

const I18nLink = memo(({
  hreflang,
  rel,
  href,
}: I18nLinkProps) => {
  useEffect(() => {
    const link = document.createElement('link')
    link.rel = rel
    link.href = href
    if (hreflang) {
      link.hreflang = hreflang
    }
    document.head.prepend(link)

    return () => {
      link.parentNode?.removeChild(link)
    }
  })

  return null
})

const I18nListenRedirect = memo(() => {
  const {i18n} = useTranslation()
  const pathname = useLocationPathname()
  const search = useLocationSearch()
  const hash = useLocationHash()
  const navigate = useNavigate()
  const lang = useParam('lang')
  const currentLangRef = useRef<string | undefined>(lang)
  const currentPathRef = useRef<string>('')

  currentLangRef.current = lang
  currentPathRef.current = `${getPathnameWithoutLang(pathname, lang as Locale | undefined)}${search}${hash}`

  useEffect(() => {
    const querystring = new URLSearchParams(search)
    if (!querystring.has('lang')) {
      return
    }
    querystring.delete('lang')
    navigate(`${pathname}${querystring.toString()}${hash}`, {replace: true})
  }, [hash, pathname, navigate, search])
  useEffect(() => {
    if (lang !== i18n.resolvedLanguage) {
      navigate(`/${i18n.resolvedLanguage}${currentPathRef.current}`, {replace: true})
    }
  }, [i18n, lang, navigate])
  useEffect(() => {
    const changeEvent = (locale: Locale) => {
      if (locale !== currentLangRef.current) {
        navigate(`/${locale}${currentPathRef.current}`)
      }
    }
    i18n.on('languageChanged', changeEvent)

    return () => {
      i18n.off('languageChanged', changeEvent)
    }
  }, [i18n, navigate])

  return null
})

const I18nListenDaysJs = memo(() => {
  const {i18n} = useTranslation()
  const [locale, setLocale] = useState(i18n.resolvedLanguage)
  const setDayjs = useSetDayjs()

  useEffect(() => {
    const changeEvent = (locale: Locale) => {
      setLocale(locale)
    }
    i18n.on('languageChanged', changeEvent)

    return () => {
      i18n.off('languageChanged', changeEvent)
    }
  }, [i18n])

  useEffect(() => {
    setDayjs((dayjs) => {
      dayjs.locale(locale)

      return dayjs
    })
  }, [locale, setDayjs])

  return null
})

const I18nSetLocale = memo(() => {
  const {i18n} = useTranslation()
  const {user} = useUserHelperGetCurrent()
  const userLocale = user?.locale

  useEffect(() => {
    if (userLocale) {
      i18n.changeLanguage(userLocale)
    }
  }, [i18n, userLocale])

  return null
})

const I18nLinks = memo(() => {
  const {i18n} = useTranslation()
  const lang = useParam('lang')
  const pathname = useLocationPathname()

  return <>
    <I18nHtml locale={i18n.resolvedLanguage}/>
    {locales.map((l: LocaleObject) =>
      <I18nLink
        key={l.code}
        rel="alternate"
        hreflang={l.code}
        href={(getUrlForLang(pathname, lang as Locale | undefined, l.code))}/>,
    )}
    <I18nLink rel="alternate" hreflang="x-default" href={(getUrlForLang(pathname, lang as Locale | undefined))}/>
  </>
})

const I18nMui = memo(({children}: PropsWithChildren) => {
  const {i18n} = useTranslation()

  return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage}>
    {children}
  </LocalizationProvider>
})

export const I18n = memo(({children}: PropsWithChildren) => {
  return <>
    <I18nSetLocale/>
    <I18nLinks/>
    <I18nListenRedirect/>
    <I18nListenDaysJs/>
    <I18nMui>
      {children}
    </I18nMui>
  </>
})
