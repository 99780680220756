import {useRef} from 'react'

export const useOnce = (callback: () => void): void => {
  const done = useRef(false)
  if (done.current) {
    return
  }
  done.current = true
  callback()
}
