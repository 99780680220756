import {memo, useEffect} from 'react'
import {useLocation, useParams} from 'react-router'
import {useSetLocation, useSetParams} from '../../../../states/location'
import {useOnce} from '../../../../hooks/useOnce'

const LocationListener = memo(() => {
  const setLocation = useSetLocation()
  const location = useLocation()

  useOnce(() => setLocation(location))
  useEffect(() => {
    setLocation(location)
  }, [location, setLocation])

  return null
})

export const ParamsListener = memo(() => {
  const setParams = useSetParams()
  const params = useParams()

  useOnce(() => setParams(params))
  useEffect(() => {
    setParams(params)
  }, [params, setParams])

  return null
})

export const RouterListener = memo(() => {
  return <>
    <ParamsListener />
    <LocationListener />
  </>
})
