import {atom, useAtomValue, useSetAtom} from 'jotai'
import type {Setter} from '../@types/setter'
import {useMemo} from 'react'
import type {Iri, UrlId} from '../@types/Api'
import {createItemIri} from '../utils/iri'

type Location = {
  pathname: string
  search: string
  hash: string
}
type Params = Readonly<Record<string, string | undefined>>

const paramsAtom = atom<Params>({})

const locationAtom = atom<Location>({
  pathname: window.location.pathname,
  search: window.location.search,
  hash: window.location.hash,
})
const pathnameAtom = atom<string>((get) => get(locationAtom).pathname)
const searchAtom = atom<string>((get) => get(locationAtom).search)
const hashAtom = atom<string>((get) => get(locationAtom).hash)

const currentRouteIdAtom = atom<UrlId | undefined>((get) => {
  return get(paramsAtom).routeId
})
const currentRouteIriAtom = atom<Iri | undefined>((get) => {
  const currentRouteId = get(currentRouteIdAtom)

  return currentRouteId ? createItemIri('routes', currentRouteId) : undefined
})

export function useLocation(): Location {
  return useAtomValue(locationAtom)
}

export function useSetLocation(): Setter<Location> {
  return useSetAtom(locationAtom)
}

export function useSetParams(): Setter<Params> {
  return useSetAtom(paramsAtom)
}

export function useLocationPathname(): string {
  return useAtomValue(pathnameAtom)
}

export function useLocationSearch(): string {
  return useAtomValue(searchAtom)
}

export function useLocationHash(): string {
  return useAtomValue(hashAtom)
}

export function useParam(param: string): string | undefined {
  const paramAtom = useMemo(() => atom<string | undefined>((get) => {
    return get(paramsAtom)[param]
  }), [param])

  return useAtomValue(paramAtom)
}

export function useCurrentRouteId(): string | undefined {
  return useAtomValue(currentRouteIdAtom)
}

export function useCurrentRouteIri(): Iri | undefined {
  return useAtomValue(currentRouteIriAtom)
}

export function useIsRouteSelected(iri: Iri): boolean {
  const isSelectedAtom = useMemo(() => atom<boolean>((get) => {
    return get(currentRouteIriAtom) === iri
  }), [iri])

  return useAtomValue(isSelectedAtom)
}
